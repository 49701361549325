div.biometrics-modal.lmn-modal-wrap {
    .lmn-modal {
        min-width: auto;

        .lmn-modal-content {
            .lmn-modal-header {
                font-size: 1.2rem;

                div.lmn-modal-title {
                    font-size: 1.2rem;
                }

                img {
                    width: 50px;
                }

                p {
                    font-weight: bold;
                    font-size: 1.2rem;
                }
            }

            .lmn-modal-body {
                text-align: center;
            }

            .lmn-modal-footer {
                justify-content: center;
            }

            .react-container {
                padding: 0;
            }
        }
    }

    .biometrics-content {
        font-size: 1rem;
        white-space: pre-line;
    }

    .biometrics-content-loading {
        height: 50px;
    }
}

div.biometrics-modal.biometrics-custom-title {

    .lmn-modal-content {
        .lmn-modal-header {
            padding-right: 0;
            border-bottom: none;
            justify-content: center;

            .biometrics-header-with-img {
                width: 100%;
                text-align: center;

                img {
                    width: 100px;
                }
            }
        }

        .lmn-modal-footer {
            border-top: none;
        }
    }
}

div.biometrics-modal.biometrics-null-title {
    .lmn-modal-content {
        .lmn-modal-header {
            margin: 0;
            border-bottom: none;
        }
    }
}