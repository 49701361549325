@import "../../common/styles/colors.scss";

.issue-new-password-container {
  padding-top: 10px !important;

  .enter-password-container {
    border-top: 1px solid #dededea0;
    margin-top: 20px;

    p {
      text-align: center;
      font-size: 1rem !important;
      font-style: italic;
    }

    p.password-instructions {
      color: #3e7d98 !important;
    }
  }
}

.custom-modal-container {
  .issue-new-password-wrapper {
    .title-container{
        span{
            font-size: 20px;
        }
    }

  }
}

.suspend-reactivate-user-container {
  padding-top: 10px !important;
}

div.users-list-dropdown-container {
  margin-top: 10px;
}

div.error-alert-container {
  margin-bottom: 20px;
}

.title-text {
  font-size: 1.2rem !important;
  text-align: center;
  margin-top: 0px;
}

div.user-info-container-bg {
  background-color: #fff;
  border: 1px solid #e9eef3;
  padding: 1rem;
  margin-top: 1rem;

  div.user-info-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;

    label.label-title {
      text-align: center;
      flex: 1;
    }

    div.label-value-container {
      text-align: left;
      flex: 1;
      display: flex;

      label.label-separator {
        color: lightgray;
        margin-right: 10px;
      }

      label.label-value {
        word-break: break-all;
      }
    }
  }
}

div.status-container {
  p.status-text {
    text-align: center;
    font-size: 1rem !important;
    margin: 10px 0px 20px 0px;
  }
}

.date-selection-container {
  border-top: 1px solid #dededea0;
  margin-top: 20px;

  .date-view {
    padding: 10px 0px;
  }

  label {
    font-size: 1.02rem !important;
  }
}

.instruction-container {
  p {
    color: #3e7d98 !important;
    text-align: center;
    font-size: 1rem !important;
    font-style: italic;
  }
}

div.loading-container {
  margin-top: 20px;
}

div.suspension-date-container
  .suspension-date
  input.lmn-datepicker-holder-input:disabled {
  color: var(--text-color-0);
  opacity: 0.4;
}

div.reactivation-date-container
  .reactivation-date
  input.lmn-datepicker-holder-input:disabled {
  color: var(--text-color-0);
  opacity: 0.4;
}
