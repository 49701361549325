.lmn-drawer-body .left-menu {
    list-style-type: none;
    padding: 0;
    margin-bottom: 0;
}

.left-menu:first-of-type {
    border-top: 1px solid #E0EAF2;
}

.left-menu-item {
    display: flex;
    align-items: center;
    min-height: 40px;
    text-align: left;
    font-size: 14px;
    font-weight: 300;
    border-bottom: 1px solid #E0EAF2;
    cursor: pointer;

    // override styles on accordion component
    div.citi-accordion {
        font-size: 14px;

        div.accordion-item {
            border: none;

            .accordion-item-header {
                padding: 10px 0;
                font-size: 14px;
                font-weight: 300;
                color: #34495F;
            }

            .accordion-item-content div {
                text-align: left;
                line-height: 30px;
            }
        }
    }
}

.left-menu-item-switch {
    justify-content: space-between;
}

.icgds .left-menu-content {
    color: #34495F;
}

.left-menu-division {
    height: 1.2rem;
    margin: 0 -0.8rem;
    background-color: #E6E6E6;
}

.menu-icon-style {
    display: flex;
    align-items: center;
    width: 20%;
    height: 40px;
    cursor: pointer;
}