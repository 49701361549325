$password-input-y-margin: 10px;
$citi-blue: #004992;
@import "../../common/styles/breakpoints.style.scss";

div.cbusol-input-group {
  width: 100%;
  margin-top: $password-input-y-margin;
  margin-bottom: $password-input-y-margin;
  position: relative;
  font-family: "Overpass";

  label.input-label {
    margin-top: 2px;
    margin-bottom: 2px;
    margin-left: 4px;
    font-size: 1.05rem;
    color: #4f6f90;
  }

  div.cbip-group {
    display: flex;
    border: 1px solid #818EB1;
    flex-wrap: nowrap;
    border-radius: 2px;

    div {
      width: 100%;
      flex: 1;
    }

    div.businesslabel-container {
      max-width: 116px;
      background-color: #ecf1f5;
      color: #586e8e;
      display: flex;
      align-items: center;
      justify-content: center;

      //border-radius: 3px 3px 0px 0px;
      &.suffix-pointer {
        cursor: pointer;
      }
      .suffix {
        display: flex !important;
        justify-content: center;
        align-items: center;
      }
    }

    input.cbusol-input {
      position: relative;
      display: block;
      width: inherit;
      box-sizing: border-box;
      font-size: 0.95rem;
      min-height: 38px;
      //border:1px solid #D9E2EA;
      border-color: none;
      border: none;
      border-radius: 0px 2px 2px 0px;
      color: #19232e;
      //padding-left:8px;
      letter-spacing: 0.02em;
      width: 100%;
      //border-color: #004992;

      &.business-code-input {
        padding-left: 5px;
      }

      &:focus {
        border-color: $citi-blue;
        outline: none;
      }
    }

    input.cbusol-input:focus {
      border: 1px solid $citi-blue;
      outline: none;
    }

    input.cbusol-input:not(.disabled):not(:disabled):not(.invalid):focus {
      border-color: #004992;
      outline: none;
    }

    input.cbusol-input.disabled[disabled] {
      background-color: #eff2f6;
      //ADA-C41630-17515
      color: #6c6c7a;
      opacity: 0.9;
    }

    span.business-label {
      display: block;
      position: relative;
      left: 0;
      bottom: 0;
      font-size: 0.95rem;
    }

    &:not(.disabled):not(:disabled):not(.invalid):focus {
      border-color: #004992;
      outline: none;
    }

    input.cbusol-input.disabled[disabled] {
      background-color: #eff2f6;
      //ADA-C41630-17515
      color: #6c6c7a;
      opacity: 0.9;
    }
  }

  i.input-label-icon {
    display: inline;
    position: absolute;
    right: 10px;
    top: 50%;
    color: #587ca0;
    font-size: 1.1rem;
    cursor: pointer;
  }

  @media only screen and (min-width: $lg) {
    label.input-label {
      font-size: 1.1rem;
    }
  }

  @media only screen and (min-width: $xl) {
    label.input-label {
      font-size: 1.12rem;
    }
  }

  @media only screen and (min-width: $xxl) {
    label.input-label {
      font-size: 1.15rem;
    }
  }
}
