@import "../../common/styles/colors.scss";

div.body-container {
    display: flex;
    height: 100%;
    flex-direction: column;

    div.message-detail-container {
        display: flex;
        flex: 1;

        div.message-detail {
            flex: 1 !important;
            display: flex;
            flex-direction: column;
            padding: 0px 5px;

            div.message-data-container {
                margin-bottom: 10px;

                div.message-data-body {
                    display: flex;
                    font-size: 0.86rem;
                    background-color: white;
                    flex-direction: column;
                    flex: 0.8;
                    max-width: 100%;
                    color: #4c4d4e;

                    div.info-item {
                        padding: 5px 0px;
                        display: flex;
                        animation-delay: 0.5s;

                        div {
                            //   flex: 1;
                            text-align: left;
                            font-size: 0.93rem;
                            text-transform: capitalize;
                            font-weight: 500;
                            color: rgb(80, 79, 79);

                            &.info-key {
                                flex: 0.5;
                                text-align: left;
                                color: #4f6f90;
                            }

                            &.info-val {
                                flex: 1;
                                font-weight: 600;
                                color: #34495f;
                                word-break: break-word;
                            }
                        }
                    }
                }
            }

            div.message-text-container {
                display: flex;
                flex: 1;

                textarea {
                    width: 100%;
                    border-color: none;
                    resize: none;
                    color: black;
                    background-color: rgba(239, 239, 239, 0.5);
                }
            }
        }

    }

    div.alert-container-success {
        margin-bottom: 10px;

        div.alert-container {
            text-align: left !important;
        }
    }
}


div.detail-header-loading {
    flex-direction: row;
    display: flex;
    text-align: left;

    span.detail-header-text {
        font-size: 1rem;
        text-align: left;
        padding-left: 5px;
        font-weight: 700;
        align-self: center;
    }
}

div.detail-header-container {
    flex-direction: column;
    display: flex;

    span.detail-header-text {
        color: rgb(87, 84, 84);
        font-size: 1rem;
        width: 100%;
        text-align: left;
        margin-left: 0px;
        padding-left: 5px;
        font-weight: 700;
    }
}

div.message-footer-button-wrap {
    display: flex;
    justify-content: space-evenly;
    align-items: stretch;
}