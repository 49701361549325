@use "@citi-icg-172888/icgds-design-tokens/themes/css-vars" as *;
@import "../../common/styles/colors.scss";

.header-container {
  display: flex;
  margin-left: -1px;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.header-bg {
  background-color: $bg-gray;
}
.fdic-logo {
  display: flex;
  width: 100%;
  padding: 1rem 1rem 0 1.3rem;
  background-color: #f8f9fb;
}

.header-content {
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  background-color: #f8f9fb;
  padding-top: 0.8rem;
}

.truncate-header-content {
  white-space: nowrap;
  justify-content: center;
  background-color: #f8f9fb;
}

.icgds .header-text-whitespace {
  white-space: pre-line;
}

.icgds .header-text {
  color: $text-headline-0;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 22px;
  margin: 0 1.2rem;
  text-align: left !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;

  span {
    max-width: 100%;
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;

    &.header-subtitle {
      display: flex;
      font-size: 1rem;
      font-weight: 300;
      line-height: 16px;
    }
  }

  br {
    display: block;
    margin: 10px 0;
  }
}

.icgds .header-back-btn {
  position: relative;
  left: 10px;
  font-size: 1.5rem;
  cursor: pointer;
  outline: none;
  color: #566e8d;
}

.header-top {
  display: flex;
  width: 100%;
  margin-top: 0.8rem;
  border-bottom: 1px solid #d9e2ea;
  padding-bottom: 0.8rem;
}

.header-logo {
  width: 60%;
  pointer-events: none;
  cursor: default;
  padding: 0.1rem 0;
}

.header-menu-start-container {
  width: 20%;
  cursor: pointer;

  .header-menu {
    color: #566e8d;
    margin-left: 1rem;
    font-size: 24px;
  }
}

.header-menu-end-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20%;
  padding-right: 0.5rem;
}

.showLogoutBtnStyle {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.header-tooltip {
  cursor: pointer;
}
